import i18n from '@/i18n';

const investmentSections = [
  {
    label: i18n.t('investmentAdvisory.fund-selection'),
    to: { name: 'InvestmentAdvisoryList', params: { section: 'fund-selection' } },
  },
  {
    label: i18n.t('investmentAdvisory.sgr'),
    to: { name: 'InvestmentAdvisoryList', params: { section: 'sgr' } },
  },
  {
    label: i18n.t('investmentAdvisory.esg'),
    to: { name: 'InvestmentAdvisoryList', params: { section: 'esg' } },
  },
  {
    label: i18n.t('investmentAdvisory.investment-solution'),
    to: { name: 'InvestmentAdvisoryList', params: { section: 'investment-solution' } },
  },
  {
    label: i18n.t('investmentAdvisory.market-trends'),
    to: { name: 'InvestmentAdvisoryList', params: { section: 'market-trends' } },
  },
];

export default investmentSections;
