import i18n from '@/i18n';

const investmentSubsections = [
  {
    id: 'daily',
    type: 'daily',
    routeName: 'MarketTrendsList',
    label: i18n.t('marketTrends.daily'),
    isArchive: true,
  },
  {
    id: 'weekly',
    type: 'weekly',
    routeName: 'MarketTrendsList',
    label: i18n.t('marketTrends.weekly'),
    isArchive: true,
  },
  {
    id: 'outlook',
    type: 'outlook',
    routeName: 'MarketTrendsList',
    label: i18n.t('marketTrends.outlook'),
    isArchive: true,
  },
  {
    id: 'currentTopics',
    type: 'currentTopics',
    routeName: 'MarketTrendsList',
    label: i18n.t('marketTrends.currentTopics'),
    isArchive: true,
  },
  {
    id: 'archive',
    type: 'daily',
    routeName: 'MarketTrendsList',
    label: i18n.t('marketTrends.archive'),
    isArchive: true,
  },
];

export default investmentSubsections;
