<template>
  <div class="investment-advisory__wrapper">
    <Tabs :tabs="sections"></Tabs>
    <Cards
      :items="items"
      :isLoading="isLoading"
      :isEmptyResponse="isEmptyResponse"
      :entity="entity"
      :routeName="routeName">
    </Cards>
    <el-pagination
      background
      @current-change="handleCurrentPageChange"
      :hide-on-single-page="true"
      :current-page.sync="currentPage"
      :page-size="perPage"
      :total="itemsTotal"
      layout="prev, pager, next">
    </el-pagination>
  </div>
</template>

<script>
import investmentSections from '@/config/investmentSections';
import marketTrendsItems from '@/config/marketTrendsItems';
import { investmentAdvisoryApi } from '@/api';
import { mapGetters } from 'vuex';
import Tabs from '@/components/Tabs.vue';
import Cards from '@/components/Cards.vue';

export default {
  name: 'InvestmentAdvisoryList',
  data() {
    return {
      items: [],
      itemsTotal: 0,
      pagesTotal: 0,
      perPage: 12, // Default page post limit
      currentPage: 1,
      isLoading: false,
      isEmptyResponse: false,
      metaQuery: true,
      metaKey: 'tab',
      sections: investmentSections,
      entity: 'investment',
      routeName: 'Investment',
    };
  },
  components: {
    Tabs,
    Cards,
  },
  created() {
    this.parseQueryFilters(this.$route.query);
    if (this.$route.params.section !== 'market-trends') {
      this.getRemoteItems(this.$route.params.section);
    } else {
      this.isEmptyResponse = false;
      this.items = marketTrendsItems;
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.parseQueryFilters(to.query);
    if (to.params.section !== 'market-trends') {
      this.getRemoteItems(to.params.section);
    } else {
      this.isEmptyResponse = false;
      this.items = marketTrendsItems;
    }
    next();
  },
  computed: {
    ...mapGetters('users', ['currentUser']),
  },
  methods: {
    async getRemoteItems(section) {
      try {
        this.isEmptyResponse = false;
        this.isLoading = true;
        const params = {
          per_page: this.perPage,
          page: this.currentPage,
          meta_key: this.metaKey,
          meta_value: section || 'on-sale',
          meta_query: this.metaQuery,
          group: this.currentUser.user_group.id,
        };
        const response = await investmentAdvisoryApi.getInvestmentAdvisory(params);
        this.isLoading = false;
        this.items = response.data;
        this.itemsTotal = parseInt(response.headers['x-wp-total'], 10);
        this.pagesTotal = parseInt(response.headers['x-wp-totalpages'], 10);
        this.isEmptyResponse = (this.items.length === 0);
      } catch (error) {
        this.isLoading = false;
        if (error && error.response) {
          this.$notify({
            type: 'error',
            dangerouslyUseHTMLString: true,
            message: error.response.data.message,
          });
        }
        throw error;
      }
    },
    handleCurrentPageChange(page) {
      this.$router.push({
        name: 'InvestmentAdvisoryList',
        query: {
          ...this.$route.query,
          page,
        },
      });
    },
    parseQueryFilters(query) {
      this.currentPage = query.page ? parseInt(query.page, 10) : 1;
    },
  },
};
</script>
